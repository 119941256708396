<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="userData.type === 'company'"
            :src="userData.logo"
            :text="avatarText(userData.name)"
            size="104px"
            rounded
          />
          <b-avatar
            v-else
            :src="userData.image"
            :text="avatarText(userData.name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'edit-company', params: { slug: userData.slug } }"
                variant="primary"
              >
                Edit
              </b-button>
              <!-- <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <!-- <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>Annual Profit</small>
            </div>
          </div> -->
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span
                v-if="userData.type === 'company'"
                class="font-weight-bold"
              >Company Name</span>
              <span
                v-else
                class="font-weight-bold"
              >Username</span>
            </th>
            <td class="pb-50">
              {{ userData.name }}
            </td>
          </tr>
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.role }}
            </td>
          </tr> -->
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td
              v-if="userData.location && userData.location.country"
              class="pb-50"
            >
              {{ userData.location.country }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              N/A
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td
              v-if="userData.location && userData.location.address"
              class="pb-50"
            >
              {{ userData.location.address }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              N/A
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.phone || 'N/A' }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <b-button
                v-if="userData.links && userData.links.crmzz"
                class="btn-icon mt-1 is-crmzz rounded-circle"
                @click="openExternalLink(userData.links.crmzz)"
              >
                <feather-icon icon="ExternalLinkIcon" />
              </b-button>

              <b-button
                v-if="userData.links && userData.links.facebook"
                class="btn-icon mt-1 is-facebook rounded-circle ml-1"
                @click="openExternalLink(userData.links.facebook)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>

              <b-button
                v-if="userData.links && userData.links.twitter"
                class="btn-icon mt-1 is-twitter rounded-circle ml-1"
                @click="openExternalLink(userData.links.twitter)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>

              <b-button
                v-if="userData.links && userData.links.tiktok"
                class="btn-icon mt-1 rounded-circle ml-1"
                style="padding: 0; width: 36px; height: 36px;"
                @click="openExternalLink(userData.links.tiktok)"
              >
                <img
                  style="width: 100%;"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABNtJREFUaEPNmmnIplMYx39jG/vUCCMi+5aQZZAPllB8sGasESG7suQDXxDZxxYiZJ1J2QYzpHy0LxGhQZQlBmXLkOj3dO6nu/PeyznneZ7pvb5M897nWv73Oee6/td1PzMYj8wBDgDmAtsBWwDrAWsH878DPwFfAJ8ArwOvAt+P6n7GCAYM8ETgZGD3QjtvAY8AjweA2WZKAGwCXAKcAayZ7bFZ4Q/gPuAm4JscmzkAVgXOAa6pHY0cXylr/wRuBK4DlqcopALYFlgI7JxidAxr3gfmAZ/12UoBcDTw0ATfeluMvwGnAE93gegDcGo4m6v0vYkJPf8XOA+4p81+F4CzuhQnFHCb2QuAO5oetgHw2HjmV17Bgba5cyeOAZ6JFzQB2Ap4B1h3mgRfhWEx3CMUwmFoMYDVgDfHnG2WADdHL8MacmzBC3ovVPt/Kt0YwOUhBxfYblV5ADg9enoRcGuhk8tCrRio1wFsDHwKrFVouE2tCcD2wMeFfjxK2wDfxQDmAxcWGu1SawLg+ueBwwr93QJcXAcgMft6jNymHlcbgE3DfduwAITcaTMJYHWEzgduLzCUotIGQF2pt6lRqpIrxnxnBUBaW0qJ+xx3AVDXzHda4D7ejdQdMVvOFcBGgcL20Yq+QHMucZctG56UHfnP2A36BOCx0ugS9Pp2IDaRCkC94wRwGyDXmJQ0AfDsG2iT5ACYL4CXgINHjN5+9xXgQ+BHYN/Qamq2CYA98VfA9cC7ke8cAEsE8HlowkswGPiVwIPAXzUDRwJPhf83AXgD2DM8lx7Y4Mu/BG9raYpMkaUCMIjZKaujNW8DhwPfNujmAChwPVQZ1AF7T1NZjrjN+wC/tCj1ATAFyixHleUlAOTmzn/c8jZZoQByj9AC4Pgo8pUAK+PZ4T7Zz1bHsukOjKtwLnMHlgJbZuzlQSHj1FXsWW1Bm2SSAAaX2IbjkEQAHh/HhfWMYzYxq7TJJAEszi1kTs2czNXlCuDqTABmsN0SX1rXskEh8zw7m0yRJgBXhVrQpm876SiyLh8BO6Q47FkzTwBOls3lKWTub2AdwH8r8U683OHoCODZ2nP9mH5njQhAMjenCjonL5v/X4ucvwAc2hCQFfZAQGeV7AR8MGLwqktH9i5paO4OQ956DGsAN4SJ9cywQ9ILj449bF0c3sZHqgSPE7u7KgC2lJKrlIbeym3j8WWDVyfY9hd+uKgfs2qpgwOrePXhoyRwdaa0lP7RMYfjjhRx+/ZLHYEHg85XZb5+yRlVholhlLHK4tAGWnX7ZPXQNB3VtzDhuf4cqww+T8WZx6GRHD1VrOKOYl7sUPCNO5gdR9rUjfdnOOmLAXiGraq7piII6xxSLQoNzbLAg3YMc59dMm11Lbf52QtoHS2qPJ2Hu05OnB4Opa14SYefnGbjde/Pc/H2dFXfM4F7x7j9paYsgsZyf5OBPvowHT4xndv1IvsACFou83DgQKVvsUTv1zDZmHJsUu5A7HDr8MkpNzuVBK6O2cbPrKbpTknZgcqAldTtlPvLSCch1Yfua1uoyBSfOQAqZbnOpeFipXCnFKByGxOGRC/rByAlAKqAJIA2QyeFIVWuLbOLRfNR4Ang5xSkOWk0x94GwP6hSjr33BxYP/q5jVM3GWz95zY/5DhpWvs/25X0E3J5FJIAAAAASUVORK5CYII="
                  alt=""
                >
              </b-button>

              <b-button
                v-if="userData.links && userData.links.instagram"
                class="btn-icon mt-1 is-instagram rounded-circle ml-1"
                @click="openExternalLink(userData.links.instagram)"
              >
                <feather-icon icon="InstagramIcon" />
              </b-button>

              <b-button
                v-if="userData.links && userData.links.linkedin"
                class="btn-icon mt-1 is-linkedin rounded-circle ml-1"
                @click="openExternalLink(userData.links.linkedin)"
              >
                <feather-icon icon="LinkedinIcon" />
              </b-button>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
