<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="company === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="company">
      <!-- First Row -->
      <b-row>
        <!-- <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        > -->
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <user-view-user-info-card :user-data="company" />
        </b-col>
        <!-- <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card />
        </b-col> -->
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-permissions-card />
        </b-col>

        <!-- <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col> -->
      </b-row>
    </template>

  </div>
</template>

<script>
/* eslint-disable */
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import UserViewUserInfoCard from './users-view/UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './users-view/UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './users-view/UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './users-view/UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
  },
  props: ['slug'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters('companies', ['company']),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getData()
    })
  },
  created() {
    // this.getData()
  },
  methods: {
    ...mapActions('companies', ['getCompanyDetailsAction']),
    getData() {
      this.getCompanyDetailsAction(this.slug)
    },
  },
}
</script>
