<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Representatives With Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="company.users"
      :fields="tableColumns"
      class="mb-0"
    >
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.image"
              :text="avatarText(data.item.name)"
              :to="{ name: 'founder-info', params: { username: data.item.username } }"
            />
          </template>
          <b-link
            :to="{ name: 'founder-info', params: { username: data.item.username } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">@{{ data.item.username }}</small>
        </b-media>
      </template>

      <template #cell(editCompanyProfile)="data">
        <b-form-checkbox
          class="ghost-center"
          disabled
          :checked="data.item.permissions.includes('editCompanyProfile')"
        />
      </template>

      <template #cell(managePropositions)="data">
        <b-form-checkbox
          class="ghost-center"
          disabled
          :checked="data.item.permissions.includes('managePropositions')"
        />
      </template>

      <template #cell(respondCompanyMessages)="data">
        <b-form-checkbox
          class="ghost-center"
          disabled
          :checked="data.item.permissions.includes('respondCompanyMessages')"
        />
      </template>

      <template #cell(manageInfluencers)="data">
        <b-form-checkbox
          class="ghost-center"
          disabled
          :checked="data.item.permissions.includes('manageInfluencers')"
        />
      </template>

      <template #cell(FAQSettings)="data">
        <b-form-checkbox
          class="ghost-center"
          disabled
          :checked="data.item.permissions.includes('FAQSettings')"
        />
      </template>

      <template #cell(id)="data">
        <b-button
          class="btn-icon is-red rounded-circle"
          variant="danger"
          @click="disconnectCompanyRepresentative(data.item)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BMedia, BLink, BAvatar, BButton,
  },
  data() {
    return {
      tableColumns: [{
        key: 'name',
        label: 'Name',
        sortable: true,
      },
      {
        key: 'editCompanyProfile',
        label: 'Edit company profile',
        sortable: false,
        class: 'text-center',
      },
      {
        key: 'managePropositions',
        label: 'Add / Edit Propositions',
        sortable: false,
        class: 'text-center',
      },
      {
        key: 'respondCompanyMessages',
        label: 'Respond to company messages',
        sortable: false,
        class: 'text-center',
      },
      {
        key: 'manageInfluencers',
        label: 'Manage Influencers',
        sortable: false,
        class: 'text-center',
      },
      {
        key: 'FAQSettings',
        label: 'Add / Edit FAQ settings',
        sortable: false,
        class: 'text-center',
      },
      {
        key: 'id',
        label: 'Disconnect',
        sortable: false,
        class: 'text-center',
      }],
    }
  },
  computed: {
    ...mapGetters('companies', ['company']),
  },
  methods: {
    ...mapActions('companies', ['disconnectCompanyRepresentativeAction']),
    disconnectCompanyRepresentative(record) {
      this.$swal({
        title: 'Are you sure?',
        html: `Disconnect <strong>${record.name}</strong> from <strong>${this.company.name}</strong> company ?`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, Disconnect!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(({ value }) => {
        if (value) {
					this.disconnectCompanyRepresentativeAction({ id: record.companyUserId, slug: this.company.slug }).finally(() => {
            this.showToast('Representative Disconnected successfully')
					})
        }
      })
    },
  },
}
</script>
